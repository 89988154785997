import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import { DateTime } from "luxon"

import Layout from "components/Layout"
import Head from "components/Head"
import CardItem from "components/CardItem"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const CategoryPage = ({ data }) => (
  <Layout>
    <Head
      id="category"
      pageTitle="すべてのアイテム"
      pageUrl={pjt.site.url + "/category/"}
    />
    <section className="section is-items is-space-half">
      <div className="card">
        <div className="inner">
          <h1 className="heading">
            <span className="icon is-fit-lg is-dark-4">
              <FontAwesomeIcon icon={["fas", "store"]} />
            </span>
            <span className="text">すべて</span>
            <span className="text is-palt is-dark-5 is-sm">
              &nbsp;全
              {data.allAirtable.edges.length}件
            </span>
          </h1>
        </div>
      </div>
      <ul className="grid is-stretch">
        {data.allAirtable.edges.map((edge, i) => (
          <li
            className="col is-mobile-12 is-tablet-6 is-desktop-6 is-wide-4"
            key={i}
          >
            <CardItem
              to={`/items/${edge.node.data.slug}/`}
              src={edge.node.data.images[0].thumbnails.large.url}
              title={edge.node.data.title}
              imageCount={edge.node.data.images.length}
            />
          </li>
        ))}
      </ul>
    </section>
  </Layout>
)

export default CategoryPage

export const query = graphql`
  query {
    allAirtable(
      filter: { table: { eq: "items" }, data: { published: { eq: true } } }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            categories {
              data {
                name
              }
            }
            images {
              url
              thumbnails {
                large {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
