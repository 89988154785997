import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class CardItem extends React.Component {
  render() {
    return (
      <Link className="card" to={this.props.to}>
        <div className="inner is-padding-lg is-space-half">
          <div className="card-thumb-wrap is-width-240px is-centering">
            <div className="card-thumb-badge is-position-absolute-top-right is-xs">
              <span className="icon">
                <FontAwesomeIcon icon={["fas", "camera-retro"]} />
              </span>
              <span className="text">{this.props.imageCount}</span>
            </div>
            <img
              data-src={this.props.src}
              className="img is-radius is-centering is-fade lazyload"
              alt={this.props.title}
            />
          </div>
          <p className="text is-dark-2 is-sm">{this.props.title}</p>
        </div>
      </Link>
    )
  }
}

export default CardItem
